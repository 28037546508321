import React from 'react';
import type { TemplateData } from '../types';
import ShopItem from './ShopItem';

type Props = {
  data: TemplateData;
};

export const ShopsList: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data.release.services
        ?.filter(({ isVisible, isCustom }) => isVisible || isCustom)
        .map((releaseLink, idx) => (
          <ShopItem key={idx} releaseLink={releaseLink} data={data} />
        ))}
    </>
  );
};
