import type { InferGetStaticPropsType } from 'next';
import type { ArtistPagePublishedProps } from '@/shared/fetchStaticData';
import { fetchArtistPageStaticData } from '@/shared/fetchStaticData';
import { ArtistPageLayout } from '@/components/Layout';
import {
  Layout as ReleasePageBaseLayout,
  ShopsList,
} from '@imus/release-page-template';
import { ReleaseGrid } from '@imus/artist-page-template';
import { useEffect, useState } from 'react';
import type { TemplateData as ReleasePageTemplateData } from '@imus/release-page-template';
import ky from 'ky';
import { PageLayer } from '@imus/services-ui';
import { ComingSoon } from '@/components/ComingSoon';
import styled from '@emotion/styled';

const PageLayerStyled = styled(PageLayer)`
  container-name: template-sizer;
  container-type: inline-size;
`;

const processReleasePageData = (
  data: ReleasePageTemplateData
): ReleasePageTemplateData => {
  return {
    ...data,
    branded: false,
    free: false,
    artistPages: undefined,
    general: {
      ...data.general,
    },
  };
};

const ReleasePageClient = ({ releasePageKey }: { releasePageKey: string }) => {
  const [data, setData] = useState<ReleasePageTemplateData>();
  useEffect(() => {
    ky.get(
      `${process.env.NEXT_PUBLIC_ARTIST_HUB_DATA_SOURCE_URL}/a/${releasePageKey}/data.json`
    )
      .then((r) => r.json())
      .then((data) =>
        setData(processReleasePageData(data as ReleasePageTemplateData))
      );
  }, []);

  if (data) {
    data.release.cover = {
      url: `${process.env.NEXT_PUBLIC_ARTIST_HUB_DATA_SOURCE_URL}/a/${releasePageKey}/cover.jpg`,
      extension: 'jpg',
    };
    return (
      <ReleasePageBaseLayout style={{ height: '100%' }} data={data}>
        <ShopsList data={data}></ShopsList>
      </ReleasePageBaseLayout>
    );
  }
  return null;
};

export function ReleasePage({
  data,
  slug,
  fileBaseUrl,
}: ArtistPagePublishedProps) {
  // _props: InferGetStaticPropsType<typeof getStaticProps>
  const [selectedReleasePageKey, setSelectedReleasePageKey] =
    useState<string>();
  return (
    <ArtistPageLayout data={data} pageId={slug} fileBaseUrl={fileBaseUrl}>
      <ReleaseGrid
        onSelect={(key) => setSelectedReleasePageKey(key)}
        releases={data.releases}
      />
      <PageLayerStyled
        isOpen={!!selectedReleasePageKey}
        close={() => setSelectedReleasePageKey(undefined)}
      >
        {selectedReleasePageKey && (
          <ReleasePageClient releasePageKey={selectedReleasePageKey} />
        )}
      </PageLayerStyled>
    </ArtistPageLayout>
  );
}

export default function Page({
  data,
  ...props
}: InferGetStaticPropsType<typeof getStaticProps>) {
  if (data.published) {
    return <ReleasePage data={data} {...props} />;
  }
  return <ComingSoon data={data} />;
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

export const getStaticProps = fetchArtistPageStaticData;
