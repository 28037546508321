import React from 'react';
import Image from 'next/image';
import type { Release } from '../types/TemplateData';
import { AutosizeGrid, ContentM, ChevronRightIcon } from '@imus/base-ui';
import styled from '@emotion/styled';
import { SurfaceCard, Text } from '@imus/services-ui';
import { clickableStyle } from 'imdui/src/components/button/Clickable';
import { useTranslation } from 'next-i18next';

const ReleaseItemStyled = styled(SurfaceCard)`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  gap: 12px;
  ${ContentM} {
    display: block;
    margin-bottom: 4px;
  }
  ${clickableStyle}
`;

const Cover = styled.div<{ imageSrc: string }>`
  position: relative;
  display: block;
  img {
    display: block;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 12px;
  }
`;

const ReleaseInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg path {
    fill: var(--on-surface);
  }
`;

const ReleaseItem = ({
  release,
  onClick,
}: {
  release: Release;
  onClick: React.MouseEventHandler;
}) => {
  const { t } = useTranslation();
  const notReleased = Date.now() < new Date(release.releaseAt).valueOf();
  const coverUrl =
    release.cover?.url ||
    `${process.env.NEXT_PUBLIC_ARTIST_HUB_DATA_SOURCE_URL}/a/${release.pageKey}/cover.jpg`;
  return (
    <ReleaseItemStyled data-test-id="ReleaseItem" onClick={onClick}>
      <Cover imageSrc={coverUrl}>
        <Image width={400} height={400} src={coverUrl} alt="release-cover" />
      </Cover>
      <ReleaseInfoRow>
        <div>
          <ContentM data-test-id="ReleaseItem-title">{release.title}</ContentM>
          <Text.BodyM style={{ color: 'var(--on-surface-var)' }}>
            {release.releaseTypeId ? t(release.releaseTypeId) : null}
            {notReleased ? ` | ${t('coming-soon')}` : ''}
          </Text.BodyM>
        </div>
        <ChevronRightIcon />
      </ReleaseInfoRow>
    </ReleaseItemStyled>
  );
};

export const ReleaseGrid = ({
  releases,
  onSelect,
}: {
  releases: Array<Release>;
  onSelect: (releasePageKey: string, r: Release) => void;
}) => {
  return (
    <>
      <AutosizeGrid gap={32} minWidth={180} maxColumnCount={3}>
        {releases.map((r, index) => (
          <ReleaseItem
            key={index}
            onClick={() => {
              onSelect(r.pageKey, r);
            }}
            release={r}
          />
        ))}
      </AutosizeGrid>
    </>
  );
};
