import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Icons } from '@imus/services-ui';
import { LinkIcon } from '@imus/base-ui';

export const shopStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  margin:
  flex: 1;
  width: 100%;
  padding: 8px;
  cursor: pointer;

  ::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: -4px;
    left: -0px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    background-color: var(--fg-4);

    border-radius: 12px;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    pointer-events: none;
  }

  svg {
    transition: transform 0.15s ease-in-out;
  }

  :hover {
    > svg {
      transform: translateX(0px);
    }

    ::before {
      opacity: 1;
    }
  }
`;

export const shopWrapperStyle = css`
  z-index: 1;
  background: var(--bg-1);
  border-radius: 12px;
  height: 56px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const shopTextStyle = css`
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ServiceIconStyled = styled(Icons.ShopIcon)`
  width: 104px;
  fill: var(--fg-1);
`;

export const ShopIcon: React.FC<{ id: string }> = ({ id }) =>
  id === 'custom' ? <LinkIcon /> : <ServiceIconStyled icon={id} />;
